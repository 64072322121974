import Camp from './images/Camp.png'
import Guides from './images/Guides.png'
import Workshops from './images/Workshops.png'
import Competitions from './images/Competitions.png' 
import CompetitiveExams from './images/CompetitiveExams.jpg'
export const images=[
    {
        img:Camp
    },
    {
        img:Guides
    },
    {
        img:Workshops
    },
    {
        img:Competitions
    },
    {
        img:CompetitiveExams
    }
]