import React from "react";
import "./Admission.css";
export default function Admission() {
  return (
    <div>
      <div className="Admissiondocimg1"></div>
      <div className="Admissiondocimg2">
        <div className="Admissiondocimg21"></div>
        <div className="Admissiondocimg22"></div>
      </div>
    </div>
  );
}
