import React from 'react';
import './ContactInfo.css'; // Import your CSS file

const ContactInfo = () => {
  return (
    <div className="contact-info-container">

      <div className='location'>
        {/*<FontAwesomeIcon icon={faMapMarkerAlt} />*/}
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
          <g clip-path="url(#clip0_487_10562)">
            <path d="M17.8072 3.09375C21.349 3.09375 24.7457 4.5007 27.25 7.00508C29.7544 9.50946 31.1614 12.9061 31.1614 16.4479C31.1614 21.009 28.6745 24.7423 26.0542 27.4205C24.7447 28.7439 23.3162 29.9439 21.7868 31.0053L21.1547 31.4356L20.8579 31.633L20.2985 31.9891L19.8 32.2933L19.1827 32.6524C18.7636 32.8909 18.2895 33.0164 17.8072 33.0164C17.3249 33.0164 16.8509 32.8909 16.4318 32.6524L15.8145 32.2933L15.0429 31.8185L14.7581 31.633L14.1497 31.2279C12.4997 30.1111 10.9626 28.8359 9.56033 27.4205C6.93996 24.7408 4.45312 21.009 4.45312 16.4479C4.45313 12.9061 5.86007 9.50946 8.36446 7.00508C10.8688 4.5007 14.2655 3.09375 17.8072 3.09375ZM17.8072 11.9965C17.2227 11.9965 16.6438 12.1116 16.1038 12.3353C15.5637 12.559 15.073 12.8869 14.6596 13.3003C14.2463 13.7136 13.9184 14.2043 13.6947 14.7444C13.471 15.2845 13.3559 15.8633 13.3559 16.4479C13.3559 17.0324 13.471 17.6113 13.6947 18.1513C13.9184 18.6914 14.2463 19.1821 14.6596 19.5955C15.073 20.0088 15.5637 20.3367 16.1038 20.5604C16.6438 20.7841 17.2227 20.8992 17.8072 20.8992C18.9878 20.8992 20.12 20.4303 20.9548 19.5955C21.7896 18.7607 22.2586 17.6284 22.2586 16.4479C22.2586 15.2673 21.7896 14.1351 20.9548 13.3003C20.12 12.4655 18.9878 11.9965 17.8072 11.9965Z" fill="#EA7878" />
          </g>
          <defs>
            <clipPath id="clip0_487_10562">
              <rect width="35.611" height="35.611" fill="white" transform="translate(0 0.126953)" />
            </clipPath>
          </defs>
        </svg>
        <span>
          58, Gazdhar Park, North Avenue, Santacruz(W),<br />
          Mumbai - 400054 </span>
      </div>

      <div className='telephone'>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
          <path d="M28.3534 6.8457H7.58028C6.3997 6.8457 5.26748 7.31469 4.43268 8.14948C3.59789 8.98427 3.12891 10.1165 3.12891 11.2971V26.135C3.12891 27.3156 3.59789 28.4478 4.43268 29.2826C5.26748 30.1174 6.3997 30.5864 7.58028 30.5864H28.3534C29.5339 30.5864 30.6662 30.1174 31.501 29.2826C32.3357 28.4478 32.8047 27.3156 32.8047 26.135V11.2971C32.8047 10.1165 32.3357 8.98427 31.501 8.14948C30.6662 7.31469 29.5339 6.8457 28.3534 6.8457ZM28.3534 9.81329L18.7087 16.4458C18.4831 16.5761 18.2273 16.6446 17.9668 16.6446C17.7064 16.6446 17.4505 16.5761 17.2249 16.4458L7.58028 9.81329H28.3534Z" fill="#EA7878" />
        </svg>
        {/*<FontAwesomeIcon icon={faPhone} />*/}
        <span>+91-22-26047978<br />
          +91-22-26047469<br />
          +91 8296049604</span>
      </div>

      <div className='address'>
        {/*<a href="mailto:example@email.com"><FontAwesomeIcon icon={faEnvelope}/></a>*/}
        {/*<FontAwesomeIcon icon={faEnvelope} />*/}
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <g clip-path="url(#clip0_487_10566)">
            <path d="M39.5273 13.1073C39.5438 13.2901 39.5522 13.4735 39.5527 13.6571C39.5527 17.1209 36.7447 19.929 33.2808 19.929C32.5515 19.9297 31.8278 19.803 31.1422 19.5545C27.9725 20.4978 24.1206 21.0514 19.9658 21.0514C15.811 21.0514 11.9592 20.4978 8.78949 19.5545C8.10388 19.803 7.38012 19.9297 6.65087 19.929C3.18701 19.929 0.378906 17.121 0.378906 13.6571C0.378906 13.4717 0.388576 13.2886 0.404329 13.1073C0.387416 12.9645 0.378927 12.8208 0.378906 12.6771C0.379062 8.05202 9.14837 4.30273 19.9659 4.30273C30.7834 4.30273 39.5527 8.05202 39.5527 12.6771C39.5527 12.8213 39.5442 12.9648 39.5273 13.1073Z" fill="#EA7878" />
            <path d="M37.3063 29.6682C36.1779 34.3968 31.1223 35.4471 25.0562 35.4471H14.8711C8.80498 35.4471 3.74932 34.3969 2.62098 29.6682C2.35794 28.566 2.50377 27.4235 3.00403 26.3786L6.5518 16.4518C7.52059 14.4283 9.73438 13.0218 12.2987 12.732V9.49466C12.2987 8.44048 13.1533 7.58594 14.2075 7.58594C15.2618 7.58594 16.1163 8.44048 16.1163 9.49466V12.6757H23.8111V9.49466C23.8111 8.44048 24.6657 7.58594 25.7199 7.58594C26.7741 7.58594 27.6287 8.44048 27.6287 9.49466V12.7321C30.1929 13.0219 32.4068 14.4283 33.3756 16.4519L36.9234 26.3787C37.4235 27.4235 37.5693 28.5659 37.3063 29.6682Z" fill="#EA7878" />
            <path d="M16.6016 17.8379C16.6016 18.4506 15.6733 18.9473 14.5282 18.9473C13.3831 18.9473 12.4548 18.4506 12.4548 17.8379C12.4548 17.2252 13.3831 16.7285 14.5282 16.7285C15.6733 16.7285 16.6016 17.2252 16.6016 17.8379ZM19.9634 16.7285C18.8183 16.7285 17.89 17.2252 17.89 17.8379C17.89 18.4506 18.8183 18.9473 19.9634 18.9473C21.1085 18.9473 22.0368 18.4506 22.0368 17.8379C22.0368 17.2252 21.1085 16.7285 19.9634 16.7285ZM25.3986 16.7285C24.2535 16.7285 23.3252 17.2252 23.3252 17.8379C23.3252 18.4506 24.2535 18.9473 25.3986 18.9473C26.5437 18.9473 27.472 18.4506 27.472 17.8379C27.472 17.2252 26.5437 16.7285 25.3986 16.7285ZM13.39 20.1146C11.9926 20.1146 10.8599 20.7206 10.8599 21.4683C10.8599 22.216 11.9927 22.8221 13.39 22.8221C14.7873 22.8221 15.9201 22.216 15.9201 21.4683C15.9201 20.7206 14.7873 20.1146 13.39 20.1146ZM19.9634 20.1146C18.566 20.1146 17.4333 20.7206 17.4333 21.4683C17.4333 22.216 18.5661 22.8221 19.9634 22.8221C21.3607 22.8221 22.4935 22.216 22.4935 21.4683C22.4935 20.7206 21.3608 20.1146 19.9634 20.1146ZM26.5368 20.1146C25.1394 20.1146 24.0067 20.7206 24.0067 21.4683C24.0067 22.216 25.1394 22.8221 26.5368 22.8221C27.9341 22.8221 29.0668 22.216 29.0668 21.4683C29.0668 20.7206 27.9341 20.1146 26.5368 20.1146ZM12.1603 24.0042C10.4904 24.0042 9.13672 24.7285 9.13672 25.622C9.13672 26.5154 10.4904 27.2397 12.1603 27.2397C13.8301 27.2397 15.1839 26.5154 15.1839 25.622C15.1839 24.7285 13.8301 24.0042 12.1603 24.0042ZM19.9634 24.0042C18.2935 24.0042 16.9398 24.7285 16.9398 25.622C16.9398 26.5154 18.2935 27.2397 19.9634 27.2397C21.6332 27.2397 22.987 26.5154 22.987 25.622C22.987 24.7285 21.6332 24.0042 19.9634 24.0042ZM27.7665 24.0042C26.0966 24.0042 24.7429 24.7285 24.7429 25.622C24.7429 26.5154 26.0966 27.2397 27.7665 27.2397C29.4363 27.2397 30.79 26.5154 30.79 25.622C30.79 24.7285 29.4363 24.0042 27.7665 24.0042Z" fill="#F4F9F9" />
          </g>
          <defs>
            <clipPath id="clip0_487_10566">
              <rect width="39.9275" height="39.9275" fill="white" transform="translate(0 0.0371094)" />
            </clipPath>
          </defs>
        </svg>
        <span>guruhkSchool@gmail.com <br />
          http://www.facebook.com/GHKMSMumbai
        </span>
      </div>

    </div>
  );
};

export default ContactInfo;

